import { useEffect } from "react";
import "./App.css";

const fontList = [
  "Architectural-QTEurotype.otf",
  "Art Deco-Metropolis Regular.otf",
  "Cosmopolitan1-Melbourne.ttf",
  "Cosmopolitan2-D-DIN.otf",
  "Elegance-QTAgateType.otf",
  "Iconic-NimbusSanL-Regu.ttf",
  "Modernist-Digits-Neutraface Display Medium.ttf",
  "Modernist-Letters-NeutraTextTF-Book.ttf",
  "Opulence-URWClassico-Regular.ttf",
  "Regency-BASKERVILLE-REGULAR.TTF",
  "Roundel-QTDoghaus.otf",
  "Stylistic-texgyreadventor-regular.otf",
  "Techno-Anta-Regular.ttf",
  "Timeless-texgyretermes-regular.otf",
];

const fontFaces = fontList.map((e) => {
  const url = `url(./fonts/${encodeURIComponent(e)})`;
  return new FontFace(e, url);
});

async function loadFontFace(fontFace) {
  const loadedFont = await fontFace.load();
  document.fonts.add(loadedFont);
}

function App() {
  useEffect(() => {
    fontFaces.forEach((fontFace) => {
      loadFontFace(fontFace);
    });
  }, []);

  return (
    <div className="container">
      <div>
        <h1>Font Demo</h1>
      </div>
      {fontList.map((e, i) => (
        <div key={`font-${i}`} className="main">
          <h2 style={{ fontFamily: `'${e}'` }} className="fontName">
            {e}
          </h2>
          <div className="compare">
            <div className="canvas">
              <span className="top-left">Before tuning</span>
              <span style={{ fontFamily: `'${e}'` }} className="textbox">
                Af$#|Testing
              </span>
            </div>
            <div className="canvas">
              <span className="top-left">After tuning</span>
              <span style={{ fontFamily: `'${e}'` }} className="textbox">
                Af$#|Testing
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default App;
